<template>
    <div class="partial attestation">
        <transition-group
            name="fade"
            mode="out-in"
            tag="section"
            @before-leave="onScrollTop"
        >
            <study
                v-if="stage.study"
                key="stage-study"
            />
            <start
                v-if="stage.start"
                key="stage-start"
                @onStart="startTesting"
            />
            <question
                v-if="stage.question"
                key="stage-question"
                :on-complete="onTestingCompleted"
                :on-submit="submitTesting"
            />
            <repeat
                v-if="stage.repeat"
                key="stage-repeat"
            />
            <attempts
                v-if="stage.attempts"
                key="stage-attempts"
            />
            <timer
                v-if="stage.timer"
                :info="stage.info"
                :on-timer-stop="loadTemplate"
                key="stage-timer"
            />
            <loading
                v-if="isLoading"
                key="stage-loading"
            />
            <wait
                v-if="stage.wait"
                key="stage-wait"
            />
            <end
                v-if="stage.end"
                key="stage-end"
            />
        </transition-group>
    </div>
</template>

<script>
    import session from '@/api/session';
    export default {
        name: "Testing",
        components: {
            Loading : () => import('./stages/Loading'),
            Wait : () => import('@/components/test/testing/stages/Wait'),
            End : () => import('./stages/End'),
            Timer : () => import('./stages/Timer'),
            Attempts : () => import('./stages/Attempts'),
            Repeat : () => import('./stages/Repeat'),
            Question : () => import('./stages/Question'),
            Study : () => import('./stages/Study'),
            Start : () => import('./stages/Start')
        },
        data() {
            return {
                stage: {},
                answers: {},
                isLoading: true
            }
        },
        methods: {
            onScrollTop() {
                window.scrollTo(0,0);
            },
            onTestingCompleted() {
                this.stage.question = false;
                this.stage.end = true;
            },
            startTesting() {
                this.stage.start = false;
                this.stage.question = true;
            },
            async submitTesting(answersList) {
                try {
                    this.stage.question = false;
                    this.isLoading = true;
                    const request = await session.post('/api/v1/testing/answer_question/', answersList);
                    let {data} = request;
                    if (data.is_wait) {
                        this.stage.wait = true;
                    } else if (data.is_passed) {
                        this.stage.end = true;
                    } else {
                        await this.$store.dispatch('knowledge_base/setMaterialsList');
                        this.stage.repeat = true;
                    }
                    this.isLoading = false;
                } catch (e) {
                    console.error(e)
                }
            },
            async loadTemplate() {
                const request = await session.get('/api/v1/testing/load_template/');
                this.stage = request.data;
                this.isLoading = false;
            }
        },
        created() {
            this.loadTemplate();
        }
    }
</script>

<style lang="scss">
    @import "#sass/v-style";
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-enter-to, .fade-leave-to {
        opacity: 0;
    }
    .out-in {
        width: 100% !important;
    }
    .hidden-ctrl {
        position: absolute;
        clip: rect(0 0 0 0);
        padding: 0;
        height: 1px;
        width: 1px;
        margin: -1px;
        border: 0;
        overflow: hidden;
    }
    .text-hlt-bad {
        color: #d0021b;
    }
    .attestation {
        display: flex;
        margin: 0 auto 12px;
        max-width: 860px;
        padding: 20px;
        background-color: #fff;
        & > section {
            width: 100%;
        }
    }
    .attestation__inner {
        width: 100%;
    }
    .attestation__head {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .attestation__question-num {
        position: absolute;
        bottom: -30px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 76px;
        font-size: 1rem;
        text-align: center;
    }
    .attestation__question-num .hlt {
        color: #999999;
    }
    .attestation__test-num {
        flex: 0 0 auto;
        width: 50px;
        height: 50px;
    }
    .attestation__title {
        margin-bottom: 0;
        color: #999999;
        font-size: 1rem;
    }
    .attestation__question-form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .attestation__question {
        margin-top: 50px;
        margin-bottom: 50px;
        max-width: 540px;
        color: #000000;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
    }
    .attestation__answer-list {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
    }
    .attestation__answer-item {
        margin-bottom: 15px;
    }
    .attestation__ctrl-label {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        border-radius: 5px;
        background-color: #f7f7f7;
        border: 2px solid transparent;
        transition: 0.5s background-color;
        cursor: pointer;
    }
    .attestation__ctrl-label:hover {
        background-color: #e3e3e3;
    }
    .attestation__ctrl-indicator {
        position: relative;
        flex: 0 0 auto;
        display: inline-block;
        margin-right: 15px;
        width: 16px;
        height: 16px;
        border: 1px solid rgba(0,0,0,0.2);
    }
    .attestation__ctrl:checked + .attestation__ctrl-label {
        background-color: transparent;
        border: 2px solid #fcc142;
    }
    .attestation__ctrl:checked + .attestation__ctrl-label .attestation__ctrl-indicator {
        background-color: #fcc142;
        border: 1px solid #fcc142;
    }
    .attestation__ctrl:checked + .attestation__ctrl-label .attestation__ctrl-indicator:after {
        content: "";
        position: absolute;
        top: 1px;
        left: 3px;
        width: 12px;
        height: 7px;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        transform: rotate(-45deg);
    }
    .attestation__ctrl[type=radio] + .attestation__ctrl-label .attestation__ctrl-indicator {
        border-radius: 50%;
    }
    .attestation__btn-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 50px;
        width: 100%;
        font-size: 16px;
        font-weight: 600;
    }
    .attestation__btn-next {
        order: -1;
        height: 46px;
        margin-bottom: 15px;
        padding: 13px 24px;
        width: 256px;
        font-size: 16px;
        line-height: 1;
        font-weight: 600;
        min-width: 224px;
        cursor: pointer;
    }
    .attestation__btn-next[disabled] {
        color: #b2b2b2;
        background-color: #eeeeee;
        border-color: #eeeeee;
        opacity: 1;
        cursor: initial;
    }
    .attestation__btn-next[disabled]:hover {
        opacity: 1;
    }
    .attestation__btn-stop {
        height: 46px;
        margin-bottom: 15px;
        padding: 13px 24px;
        width: 256px;
        color: #ea1c18;
        font-size: 16px;
        line-height: 1;
        font-weight: 600;
        border-radius: 40px;
        border: 2px solid #ea1c18;
        cursor: pointer;
    }
    .attestation__btn-stop:hover {
        color: #ea1c18;
        border: 2px solid #f37b78;
    }

    @media (min-width: 768px) {
        .attestation {
            padding: 30px;
        }
        .attestation__ctrl-label {
            padding: 20px;
        }
        .attestation__question-num {
            position: static;
        }
        .attestation__question {
            margin-top: 30px;
            font-size: 22px;
        }
        .attestation__btn-next {
            order: 1;
        }
        .attestation__btn-wrap {
            display: flex;
            justify-content: space-between;
            margin-top: 100px;
        }
    }

    @media (min-width: 1380px) {
        .test-repeat {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            padding-left: 290px;
        }
        .test-repeat__finally {
            padding-left: 0 !important;
        }
        .attestation__answer-list {
            padding: 0 50px;
        }
        .attestation__btn-wrap {
            padding: 0 50px;
        }
    }

    @media (min-width: 1000px) {
        .test-repeat,
        .test-start,
        .test-timer {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            padding-left: 290px;
        }
        .test-repeat__finally {
            padding-left: 0 !important;
        }
    }
    /* Начало аттестации */
    .test-start,
    .test-repeat,
    .test-timer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 657px;
    }
    .test-start__pic,
    .test-repeat__pic {
        margin-bottom: 35px;
        overflow: hidden;
    }
    .test-start__title,
    .test-repeat__title,
    .test-timer__title,
    .test-end__title {
        width: 260px;
        color: #000000;
        font-size: 1.5rem;
        font-weight: 600;
        text-align: center;
    }
    .test-start__title {
        margin-bottom: 100px;
    }
    .test-start__btn {
        height: 46px;
        padding: 13px 24px;
        width: 256px;
        font-size: 16px;
        line-height: 1;
        font-weight: 600;
        min-width: 224px;
        cursor: pointer;
    }

    /* Повторная аттестация */
    .test-repeat__head {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .test-repeat__title {
        margin-bottom: 15px;
    }
    .test-repeat__subtitle {
        margin-bottom: 50px;
        width: 260px;
        color: #000000;
        font-size: 1rem;
        text-align: center;
    }
    .test-repeat__list {
        list-style: none;
        padding: 0;
        margin: 40px 0 0 0;
    }
    .test-repeat__item {
        padding: 15px 0;
        border-bottom: 1px solid #e8e8e8;
    }
    .test-repeat__article {
        font-size: 1rem;
    }

    /* Таймер */
    .test-timer__title {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
        width: 182px;
        text-align: center;
    }
    .test-end__title {
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
        width: 200px;
        text-align: center;
    }
    .test-timer__pic,
    .test-end__pic {
        margin-bottom: 50px;
    }
    .test-timer__diagram {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 194px;
        height: 194px;
        margin-bottom: 78px;
    }
    .test-timer__diagram-text {
        flex: 0 0 auto;
        word-wrap: none;
        font-size: 39px;
        line-height: 1;
    }

    .test-end__link-to-main {
        border-radius: 50px;
        padding: 0 25px;
        cursor: pointer;
        color: #000;
        background: #FBC04F;
        font-size: 1rem;
        font-weight: 600;
        max-height: 37px;
        height: 37px;
    }

    .test__link-to-main {
        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 50px;
        padding: 0 25px;
        cursor: pointer;
        color: #000;
        background: #FBC04F;
        font-size: 1rem;
        font-weight: 600;
        max-height: 37px;
        height: 37px;
    }

    .test__link-to-main:hover {
        opacity: 0.9;
        color: #000;
        background: #FBC04F;
    }

    @media (min-width: 768px) {
        .test-start__title,
        .test-repeat__title,
        .test-timer__title,
        .test-repeat__subtitle {
            width: 484px;
        }
        .test-timer__title {
            flex-direction: row;
            width: auto;
            text-align: left;
        }
        .test-end__title {
            width: auto;
        }
        .test-timer__pic {
            margin-right: 15px;
            margin-bottom: 0;
        }
    }

    .attestation__modal-dialog {
        width: 306px;
    }
    .attestation__modal-content {
        padding: 50px 28px;
    }
    .attestation__modal-text {
        margin-bottom: 30px;
        color: #000000;
        font-size: 22px;
        font-weight: 600;
        line-height: 1.3;
    }
    .attestation__end-btn {
        background-color: #ea1c18;
        margin-bottom: 15px;
        padding: 13px 24px;
        height: 46px;
        width: 250px;
        color: #fff;
        font-size: 16px;
        line-height: 1;
        font-weight: 600;
        text-align: center;
        border-radius: 40px;
        border: 2px solid #ea1c18;
        cursor: pointer;
    }
    .attestation__end-btn:hover {
        color: #fff;
    }
    .attestation__continue-btn {
        margin-bottom: 15px;
        padding: 13px 14px;
        height: 46px;
        width: 250px;
        font-size: 1rem;
        line-height: 1;
        font-weight: 600;
        color: #000;
        background-color: #eeeeee;
        border-radius: 40px;
        border: none;
        cursor: pointer;
    }
</style>
