<template>
    <content-view>
        <testing/>
    </content-view>
</template>

<script>
import ContentView from '@/views/menu/ContentView'
import Testing from '@/components/test/testing/Testing'
import { metaTitle } from '@/mixins/meta_title'

export default {
    name: 'UserTesting',
    mixins: [metaTitle],
    components: { ContentView, Testing }
}
</script>

<style scoped>

</style>
